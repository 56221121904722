import styled from "styled-components";

interface SliderSectionProps {
  isOpen: boolean;
}

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 999;
`;

export const HomeContainer = styled.div`
  width: 100vw;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #000000cc;
  padding: 0px 32px;
  position: fixed;
  @media (max-width: 768px) {
    background-color: black;
    height: 46px;
  }
`;

export const HeaderLogo = styled.img`
  height: 32px;
  width: 88px;
  cursor: pointer;
  @media (max-width: 720px) {
    width: 58px;
    height: 21px;
  }
`;

export const MenuItemsSection = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  /* color: ${(props) => props.color || "black"}; */
  color: white;
  margin: 0px 7.5vw 0px 5vw;
  svg {
    /* display: none; */
    @media (max-width: 768px) {
      display: block;
      cursor: pointer;
    }
  }
  .menu-link {
    margin: 0px 20px;
    transition: all 0.5s;

    @media (max-width: 768px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 14px;
    }
    @media (max-width: 768px) {
      margin: 0px 12px;
    }
  }

  .react-toggle-track-x {
    height: 100%;
    div {
      height: 100%;
      display: flex;
      align-items: center;
      transform: translate(-5px, -1px);
    }
  }
  @media (min-width: 768px) and (max-width: 860px) {
    margin: 32px;
  }
  @media (max-width: 768px) {
    margin: 0px;
  }
`;

export const LanguageSwitcher = styled.div<any>`
  color: ${(props) => props.color || "white"};
  border-bottom: ${(props) => props.borderBottom || "none"};
  cursor: pointer;
  margin: 0px 12px;
`;

export const MenuItem = styled.div<any>`
  display: flex;
  position: relative;
  z-index: 3;

  &:hover {
    color: #f93d66;
  }

  .submenu-link {
    display: none;
  }

  .empty-item {
    display: none;
  }

  .desktop-menu-item {
    color: ${(props) =>
      props.isActive
        ? "#f93d66"
        : props.isGrayFont
        ? "rgb(161,161,161)"
        : "white"};

    &:hover {
      color: #f93d66;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
  .mobile-menu-item {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  &:hover {
    .submenu-container {
      display: block;
      position: absolute;
      top: -4px;
      left: 0px;
      background-color: transparent;
      width: max-content;
      .submenu-section {
        display: flex;
        flex-direction: column;
        border-radius: 0px 0px 12px 12px;
        padding: 0px 16px 12px 16px;
        background: #000000cc;
      }
    }
    .submenu-link {
      display: block;
      color: white;
      padding: 10px 12px;
      width: 100%;
      margin: 0px !important;
      &:hover {
        color: #f93d66;
      }
    }
    .empty-item {
      display: block;
      height: 47px;
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    border-bottom: 1px solid #1d1d1d;
    width: 100%;
    padding: 4px 8px;
    .desktop-item {
      display: none;
    }
  }
`;

export const SubMenuContainer = styled.div`
  display: none;
`;

export const HamburgerMenuIconSection = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const SliderSection = styled.div<SliderSectionProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 0vw;
  height: calc(100vh - 61px);
  top: 61px;
  margin-top: 32px;
  transition: margin 0.5s ease;
  overflow: scroll;
  padding: 12px 0px;
  .menu-link {
    display: none;
  }
  @media (max-width: 768px) {
    opacity: ${(props) => (props.isOpen ? "1" : "0")};
    width: 100vw;
    margin-left: ${(props) => (props.isOpen ? "-32px" : "calc(-100vw - 32px)")};
    margin-top: 30px;
    background-color: black;
    color: white;
    height: calc(100vh);
    z-index: 9999;
    align-items: flex-start;
    padding: 32px 32px 32px 16px;
    margin-top: -16px;
    .menu-link {
      margin: 8px;
      font-size: 14px;
      display: block;
      width: 100%;
      /* padding: 6px; */
    }
  }
`;

export const LocationDropDownContainer = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 99;
  /* width: 40px; */
  margin: 0px 20px;
  z-index: 99;
  .selected-country {
    width: 100%;
    border: 1px solid white;
    padding: 0px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 768px) {
    /* width: 40px; */
    margin: 0px 12px;
  }
  /* &:hover {
    color: #f93d66;
  } */
  .empty-item {
    height: 46px;
    @media (max-width: 768px) {
      height: 34px;
    }
  }
`;

const LocationContainer = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LocationDropDownWrapper = styled.div`
  position: absolute;
  right: calc(0%);
  width: 175px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const LocationDropdown = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 26px 12px 26px;
  border-radius: 0px;
  background: #000000cc;
  border-top: 0.5px solid black;
  align-items: flex-end;
  @media (max-width: 768px) {
    padding: 0px 12px 12px 12px;
  }
  .active {
    color: #f93d66;
  }
`;

export const Country = styled.div`
  color: white;
  padding: 6px 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 4px 0px;
    font-size: 16px;
  }
  &:hover {
    color: #f93d66;
  }
`;
