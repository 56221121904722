
const LRUCache = require('lru-cache');

const ssrCache = new LRUCache({
    maxSize: 100 * 1024 * 1024 * 102,
    sizeCalculation: (value, key) => {
        return 1
      },
    maxAge: 1000 * 60 * 60 * 24 * 30
});


module.exports = ssrCache;

